// Import
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

// Modules
$font_serif: "Tinos", serif;
$font_sans: "Roboto", sans-serif;
$font_size_caption: 0.75rem; // 12px
$font_size_body: 1rem; // 16px
$font_size_body_large: 1.125rem; // 18px
$font_size_h5: 1.25rem; // 20px
$font_size_h4: 1.5rem; // 24px
$font_size_h3: 1.75rem; // 28px
$font_size_h2: 2rem; // 32px
$font_size_h1: 2.25rem; // 36px

// Mixins

// Global Settings
html {
  font-size: 100%; // In most browsers, this defaults to 16 pixels
}
body {
  font-family: $font_sans;
}