@import "../../Global.scss";

// Colors
$bg-color: #f4f4f4;
$dot-color: rgb(0, 0, 0);

// Dimensions
$dot-size: 1px;
$dot-space: 16px;

@mixin dots($property, $value) {
  content: "";
  position: absolute;
  top: -16px;
  $property: $value;
  background: linear-gradient(
        90deg,
        $bg-color ($dot-space - $dot-size),
        transparent 1%
      )
      center,
    linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%) center,
    $dot-color;
  background-size: $dot-space $dot-space;
  width: 90%;
  height: 110%;
  z-index: -1;
}

// Image Article
.image_article {
  margin: 48px 0 200px 0;
  &.left_image {
    .article_visual {
      &::after {
        //@include dots(left, -10px);
      }
    }
  }

  &.right_image {
    .article_visual {
      &::after {
        //@include dots(right, -10px);
      }
    }
  }
  .article_visual {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @media screen and (max-width: 720px) {
      margin-bottom: 32px;
    }
    .plx_wrapper {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      @media screen and (max-width: 720px) {
        transform: scale(0.8);
      }
      .plx_fg {
        position: absolute;
      }
    }
    img {
      width: auto;
      height: auto;
      max-width: 480px;
      max-height: 480px;
    }
    .content_automotive {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-height: 360px;
      .cont_auto_para_fg {
        position: absolute;
        height: 100%;
        width: 100%;
      }
      .cont_auto_bg {
        min-width: 320px;
        max-width: 440px;
      }
      .custom-class {
        align-self: flex-end;
      }
      .cont_auto_fg {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 162px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 320px;
          height: 192px;
          margin: -96px 0 0 -160px;
          background: url(../../assets/images/Device.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .article_body {
    h2 {
      font-family: $font_serif;
      font-size: $font_size_h3;
      font-weight: 900;
      margin: 16px 0;
    }
    .article_text {
      font-size: $font_size_body_large;
      a {
        text-decoration: underline;
        color: rgba(0, 0, 0, 0.5);
        transition: ease 0.2s;
        &:hover {
          color: rgba(0, 0, 0, 0.9);
        }
      }
    }
  }
}

.image_article {
  &.design_system {
    .plx_wrapper {
      .plx_bg {
        img {
          //transform: rotate(45deg);
          max-width: 560px;
        }
      }
      .plx_fg {
        left: 50%;
        margin-left: -127px;
        img {
          transform: scale(0.6) ;
        }
      }
    }
  }
  &.automotive {
    .plx_wrapper {
      .plx_fg {
        bottom: -24px;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 320px;
          height: 190px;
          margin: -99px 0 0 -160px;
          background: url(../../assets/images/Device.png);
          background-size: cover;
          background-repeat: no-repeat;
        }
      }
      .plx_bg {
        img {
          transform: scale(0.9) translateX(20px);
        }
      }
    }
  }
  &.prototype {
    .plx_fg {
      display: flex;
      align-items: center;
      video {
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
        margin-top: 8px;
      }
    }
    .plx_bg {
      img {
        transform: scale(0.8);
      }
    }
  }
}

.isInMiddle {
  .automotive {
    color: white;
    transition: ease 0.3s;
  }
  .prototype {
    color: white;
    transition: ease 0.3s;
  }
}
