@import "../../Global.scss";

.Footer {
  width: 100vw;
  background: #1a1a1a;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 24px;
  color: white;
  position: relative;
  .main_title {
    font-family: "Tinos", serif;
    font-size: $font_size_h3;
    font-weight: 900;
    margin-bottom: 36px;
    text-align: center;
    z-index: 1;
  }
  .mailto {
    border: none;
    display: flex;
    border-radius: 8px;
    padding: 16px 24px 16px 22px;
    font-family: "Roboto", sans-serif;
    font-size: $font_size_body;
    font-weight: 400;
    background: #00000069;
    color: white;
    text-decoration: none;
    transition: ease 0.2s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 0%;
      height: 100%;
      background: #595959;
      transition: ease 0.2s;
      z-index: 0;
    }
    &:hover {
      &::before {
        width: 100%;
      }
    }
    span {
      padding: 0 0 0 44px;
      position: relative;
      z-index: 2;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: -5px;
        background: url(../../assets/images/Socials/Email.svg);
        width: 32px;
        height: 32px;
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
      }
    }
    &:active,
    &:focus {
      border: none;
      background: #2b2b2baf;
    }
  }
  .socials {
    display: flex;
    padding: 56px 0;
    .social {
      margin: 0 12px;
      width: 40px;
      height: 40px;
      opacity: 0.7;
      transition: ease 0.3s;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 32px;
        height: 32px;
      }
      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
  .copyright {
    opacity: 0.7;
    z-index: 1;
  }
}

.object_container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}
.mattertestbutton {
  margin-top: 120px;
}