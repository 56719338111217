// Import
@import "./Global.scss";

#root {
  overflow-x: hidden;
}

#wrapper {
  background: #f5f5f5;
  transition: 0.4s ease;
  &.isInSpecialities {
    background: #ffcc26;
  }
  &.isInMiddle {
    background: #2d2d2d;
  }
}

// Hero Section
.hero {
  position: relative;
  width: 100vw;
  background: rgba(0, 0, 0, 1);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  .hero_core {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(100vw - 72px);
    max-width: 960px;
    margin-top: 40vh;
    z-index: 3;
    @media screen and (max-width: 720px) {
      height: 400px;
      width: calc(100vw - 56px);
      margin-top: 10vh;
    }
    h2 {
      font-family: $font_serif;
      font-size: $font_size_h2;
      font-weight: 500;
      z-index: 1;
    }
    .hero_graphic {
      z-index: 1;
      display: none;
      svg {
        path {
          fill: rgb(255, 255, 255);
        }
        width: 80%;
        max-width: 620px;
        min-width: 310px;
        height: auto;
      }
    }
    .hero_desc {
      font-family: $font_sans;
      font-size: $font_size_h4;
      font-weight: 200;
      line-height: 2.5rem;
      min-width: calc(100% - 40px);
      max-width: 640px;
      width: 60%;
      z-index: 1;
      padding: 3vh 0 0 0;
      @media screen and (max-width: 720px) {
        font-size: $font_size_h5;
      }
      .link_hero {
        text-decoration: none;
        margin: 0 4px;
        color: black;
        padding: 0px 8px;
        font-style: italic;
        font-weight: 900;
        display: inline-block;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 32px;
          left: 0;
          top: 3px;
          background: #5ce617;
          z-index: -1;
          border-radius: 2px;
        }
        &::after {
          content: "";
          position: absolute;
          width: 0%;
          height: 32px;
          left: 0;
          top: 3px;
          background: #e6be1c;
          z-index: -1;
          transition: 0.2s ease;
          border-radius: 2px;
        }
        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
      span {
        margin: 0 6px;
      }
    }
  }
  .hero_side {
    font-size: $font_size_caption;
    position: absolute;
    z-index: 1;
    @media screen and (max-width: 1024px) {
      display: none !important;
    }
    &.hero_left {
      left: -90px;
      transform: rotate(-90deg) translate3d(-3vh, 0, 0);
      display: flex;
      li {
        list-style: none;
        &:first-child {
          padding-right: 32px;
        }
      }
    }
    &.hero_right {
      right: -30px;
      transform: rotate(90deg) translate3d(3vh, 0, 0);
    }
  }
}

// Matter Area
.MatterArea {
  position: absolute;
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 40%;
    bottom: 0;
    right: 0;
    z-index: 2;
  }
}

// Section Title
.section_title {
  text-align: center;
  padding: 200px 0 72px 0;
  @media screen and (max-width: 720px) {
    padding: 168px 0 40px 0;
  }
  .main_title_container {
    display: inline-block;
    background: #000000;
    border-radius: 2px;
    width: auto;
    padding: 2px 0;
    position: relative;
    z-index: 0;
    h2 {
      display: inline;
      font-family: $font_sans;
      font-size: $font_size_h2;
      font-style: italic;
      font-weight: 900;
      color: white;
      padding: 2px 12px;
      border-radius: 2px;
      width: auto;
      margin-bottom: 8px;
      z-index: 1;
    }
  }
  .sub_title {
    margin-top: 12px;
    font-size: $font_size_body;
  }
}

// Image Card

.image_card {
  padding: 8px 8px 32px 8px;
  opacity: 0;
  transition: 0.6s ease;
  transform: scale(0.95);
  &.imagecard_1 {
    transition-delay: 0.3s;
  }
  &.imagecard_2 {
    transition-delay: 0.4s;
  }
  &.imagecard_3 {
    transition-delay: 0.5s;
  }
  h2 {
    font-family: $font_serif;
    font-size: $font_size_h4;
    text-align: center;
  }
  .card_visual {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    img {
      width: 240px;
    }
  }
  .card_text {
    margin-top: 24px;
    font-size: $font_size_body;
  }
}

.visibleInView {
  .image_card {
    opacity: 1;
    transform: scale(1);
  }
}

// Container
.container {
  #philosophies,
  #specialities {
    padding: 40px 0;
    @media screen and (max-width: 720px) {
      padding: 0;
    }
  }
}

// Matter Test

.object_container {
  width: 800px;
  height: 560px;
  background-color: skyblue;
}
.object_ball {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    top: -10px;
    left: 20px;
    background-color: tomato;
  }
}
