@import "../../Global.scss";

.Header {
  position: fixed;
  z-index: 5;
  top: 0;
  height: 88px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0);
  color: white;
  padding: 0 48px;
  backdrop-filter: blur(0px);
  transition: ease 0.2s;
  .header_logo {
    opacity: 0;
    transition: ease 0.2s;
  }
}

.show {
  .header_logo {
    opacity: 1 !important;
  }
  .Header {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(20px);
  }
}

.Nav {
  display: flex;
  align-items: center;
  justify-items: center;
}
.Nav a {
  color: rgba(255, 255, 255, 0.8);
  font-size: $font_size_body;
  font-weight: 500;
  transition: 0.5s;
  text-decoration: none;
  margin-left: 40px;
}
.Nav a:hover {
  color: rgba(255, 255, 255, 1);
  text-decoration: none;
}

.Nav button:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 0px 0px rgba(255, 0, 0, 0.25);
}

.Burger {
  display: none;
  padding: 0;
  //justify-self: end;
  border: none;
  background: none;
  transition: 0.3s ease;
  width: 48px;
  height: 48px;
}
.Burger:active {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 50%;
}

@media (max-width: 700px) {
  .Header {
    padding: 0 20px 0 24px;
    height: 64px;
  }
  .Nav {
    display: flex;
    padding: 16px 0 16px;
    background: rgba(0, 0, 0, 0);
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 64px);
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    visibility: hidden;
    a {
      margin: 0;
      padding: 16px 0;
      font-size: $font_size_h5;
      color: rgb(255, 255, 255);
      opacity: 0;
      transform: translateY(0);
      transition: ease 0.0s;
    }
    &::before {
      content: "";
      position: absolute;
      width: 120px;
      height: 120px;
      top: -64px;
      right: 0;
      background: rgb(55, 55, 55);
      z-index: -1;
      border-radius: 100%;
      transform: scale(0);
    }
    &.opened {
      visibility: visible;
      &::before {
        transform: scale(12);
        transition: 0.6s ease-in-out;
      }
      a {
        opacity: 1;
        transition: ease 0.4s;
        transition-delay: 0.3s;
        transform: translateY(16px);
        &:nth-child(2) {
          transition: ease 0.4s;
          transition-delay: 0.4s;
          transform: translateY(16px);
        }
        &:nth-child(3) {
          transition: ease 0.4s;
          transition-delay: 0.5s;
          transform: translateY(16px);
        }
      }
    }
  }
  .Burger {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* Menu Icon Animation */

#nav-icon {
  width: 24px;
  height: 28px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  margin-top: 8px;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0px;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 8px;
}

#nav-icon span:nth-child(4) {
  top: 16px;
}

.open #nav-icon span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.open #nav-icon span:nth-child(2) {
  transform: rotate(45deg);
}

.open #nav-icon span:nth-child(3) {
  transform: rotate(-45deg);
}

.open #nav-icon span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
